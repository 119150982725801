<template>
  <div id="target-wrapper">
    <component
      v-if="assetType !== null"
      :is="getTargetComponent"
      :associatedId="associatedId"
      :assetSrc="assetSrc"
      :assetDescription="assetDescription"
      :targetName="targetName"
      :targetDescription="targetDescription"
      :points="points"
      :awardedPoints="awardedPoints"
      :thumbnailSrc="thumbnailSrc"
      :specialFlag="specialFlag"
      :scan="scan"
    />
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import TargetText from '@/components/TargetText.vue';
import TargetImage from '@/components/TargetImage.vue';
import TargetVideo from '@/components/TargetVideo.vue';
import TargetLink from '@/components/TargetLink.vue';
import TargetPersonalityQuiz from '@/components/TargetPersonalityQuiz.vue';
import TargetTrivia from '@/components/TargetTrivia.vue';

export default {
  data() {
    return {
      isComplete: null,
      awardedPoints: null,
      targetName: null,
      targetDescription: null,
      points: null,
      iconSrc: null,
      associatedId: null,
      assetType: null,
      assetSrc: null,
      thumbnailSrc: null,
      assetDescription: null,
      specialFlag: null,
      scan: null,
    };
  },
  mounted() {
    this.getTargetData();
  },
  computed: {
    getTargetComponent() {
      switch (this.assetType) {
        case 'image':
          return TargetImage;
        case 'video':
          return TargetVideo;
        case 'link':
          return TargetLink;
        case 'quiz':
          return TargetPersonalityQuiz;
        case 'trivia':
          return TargetTrivia;
        default:
          return TargetText;
      }
    },
  },
  methods: {
    async getTargetData() {
      this.scan = !!this.$route.query?.scan;
      this.$router.replace({ query: null });
      const { target, isComplete, awardedPoints } = (
        await ApiService.post(`/target/${this.$route.params.id}/scan`, { scan: this.scan })
      ).data;
      const { name, description, points, iconSrc, targetAsset } = target;
      const { associatedId, assetType, assetSrc, thumbnailSrc, assetDescription, specialFlag } = targetAsset;
      this.isComplete = isComplete;
      this.awardedPoints = awardedPoints;
      this.targetName = name;
      this.targetDescription = description;
      this.points = points;
      this.iconSrc = iconSrc;
      this.associatedId = associatedId;
      this.assetType = assetType;
      this.assetSrc = assetSrc;
      this.thumbnailSrc = thumbnailSrc;
      this.assetDescription = assetDescription;
      this.specialFlag = specialFlag;
    },
  },
};
</script>
