<template>
  <div class="target">
    <XIcon class="x-icon" @click="goHome" />
    <h1>Trivia</h1>
    <hr class="separator" />
    <h2>{{ targetName }}</h2>
    <p class="paragraph">
      {{ targetDescription }}
    </p>
    <PassportButton :to="`/trivia/${associatedId}?tid=${$route.params.id}`" label="START" />
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';
import PassportButton from '@/components/PassportButton.vue';

export default {
  name: 'TargetTrivia',
  components: {
    XIcon,
    PassportButton,
  },
  props: {
    targetName: {
      type: String,
      required: true,
    },
    targetDescription: {
      type: String,
      required: true,
    },
    assetSrc: {
      type: String,
      required: true,
    },
    assetDescription: {
      type: String,
      required: true,
    },
    associatedId: {
      type: String,
      required: true,
    },
  },
  methods: {
    goHome() {
      this.$router.push('/stamps');
    },
  },
};
</script>

<style lang="scss" scoped>
.target {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-image: url(../assets/images/bg_main_repeating@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.paragraph {
  padding: 10px;
  font-size: 18px;
  text-align: left;
}
.x-icon {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 4em;
  height: 1.2em;
  margin-top: 1em;
}
.separator {
  width: 6em;
}
</style>
