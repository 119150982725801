<template>
  <div class="target">
    <XIcon class="x-icon" @click="goHome" />
    <h1>{{ targetName }}</h1>
    <hr class="separator" />
    <div class="image-container">
      <img class="image" :src="fullAssetUrl" />
    </div>

    <p class="paragraph">
      {{ targetDescription }}
    </p>
    <Points
      v-if="scan"
      :points="awardedPoints ? points : 0"
      attempted="Oops! You've already earned points for this target."
    />
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';
import Points from '@/components/Points.vue';

export default {
  name: 'TargetImage',
  components: {
    XIcon,
    Points,
  },
  props: {
    assetSrc: {
      type: String,
      required: true,
    },
    targetName: {
      type: String,
      required: true,
    },
    targetDescription: {
      type: String,
      required: true,
    },
    points: {
      type: Number,
      required: true,
    },
    awardedPoints: {
      type: Boolean,
      required: true,
    },
    scan: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    fullAssetUrl() {
      return `${process.env.VUE_APP_ASSET_URL}/${this.assetSrc}`;
    },
  },
  methods: {
    goHome() {
      this.$router.push('/stamps');
    },
  },
};
</script>

<style lang="scss" scoped>
.target {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-image: url(../assets/images/bg_main_repeating@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.paragraph {
  padding: 10px;
  font-size: 18px;
  text-align: left;
}
.x-icon {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 4em;
  height: 1.2em;
  margin-top: 1em;
}
.separator {
  width: 6em;
}
.image {
  width: 315px;
  height: 300px;
  margin-top: 1.5em;
}
</style>
